import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Link,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import { useState } from "react";

export function Home() {
  const [accordionState, setAccordionState] = useState({});
  function toggleAccordion(name) {
    let newState = Object.copy(accordionState);
    newState[name] = !accordionState[name];
    setAccordionState(newState);
  }
  return (
    <>
      <h2>Wheelrights</h2>
      <h3>Uniting stolen bikes with their owners</h3>
      <Accordion
        expanded={accordionState["overview"]}
        onChange={() => toggleAccordion("overview")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>Overview</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ textAlign: "left" }}>
          <p>
            Most metropolitan areas have a serious problem with bikes thefts. In
            some cases these are inexpensive bikes, but often they are high end
            bikes worth $500 to $5000. It remains unclear where most of these
            bikes go, but some are sold online. Currently, a number of dedicated
            people check sites like OfferUp frequently and then try to match ads
            with thefts that have been reported. This can get unwieldy because
            multiple people are scanning the same ads and using ad hoc systems
            to keep notes.
          </p>
          <p>
            On the flip side, information about thefts may be scattered among
            Facebook groups, national organizations like BikeIndex, and police
            reports.
          </p>
          <h2>A Solution</h2>
          <p>
            Wheelrights provides a centralized platform where Ads and Thefts are
            listed, Matches are evaluated and Sellers are tracked.
          </p>
          <h3>
            <a href="./ads">Advertisements</a>
          </h3>
          <p>
            First, we use BrightData to import information from online ads that
            are posted on OfferUp and Facebook Marketplace. For the other sites,
            data can be entered manually. Once the ads are in our system, they
            are manually reviewed and dedicated volunteers.
          </p>
          <h3>
            <a href="./thefts">Thefts</a>
          </h3>
          <p>
            Second, we gather information about bikes that have been reported as
            stolen on{" "}
            <Link href="https://bikeindex.org" target="_blank">
              BikeIndex.org
            </Link>{" "}
            In the past, reports from the Twin Cities Stolen Bikes Facebook
            group were also imported, but the process was overly burdensome.
            Once thefts have been imported, they are manually reviewed to verify
            the information. As with ads, the essential information is brand and
            color, with model being used to narrow the matching process further.
          </p>
          <h3>
            <a href="./matches">Matches</a>
          </h3>
          <p>
            Next, the system tries to match ads with thefts. Wheelrights matches
            on brand and color, and also uses the model if that has been
            entered. Potential matches are reviewed by users. Although there are
            many false hits, the system remembers the decisions.
          </p>
          <h3>
            <a href="./sellers">Sellers</a>
          </h3>
          <p>
            We use the word "Seller" to refer to the person who sells a bike and
            the word "Alias" to refer to their profile on a platform like
            Facebook or OfferUp. A seller can have multiple aliases, either on a
            single platform or across several. For some ads, we capture
            information about the seller. Those ads have a dark, active
            silhouette on the ad or a picture of the seller. Clicking on the
            silhouette opens a page on which you can see all the bikes the
            seller has listed and enter information about the seller.
          </p>
          <h3>
            <a href="./admin">Reports</a>
          </h3>
          <p>The Reports tab provides some useful reports. [TODO]</p>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={accordionState["instructions"]}
        onChange={() => toggleAccordion("instructions")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>Instructions</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ textAlign: "left" }}>
          <p>
            While the system can retrieve some data automatically, you play an
            essential role reviewing advertisements, thefts and matches. You can
            also investigate sellers and provide details that will help with
            recoveries.
          </p>
          <h3>Advertisements</h3>
          Clicking on the <a href="./ads">ADS</a> tab displays a list of
          advertisements that have been captured in the system. The ads are
          presented in reverse chronological order and it uses 'infinite' scroll
          to display more ads when you scroll to the bottom. By default, it will
          show new ads that have not yet been reviewed. The radio buttons at the
          top of the screen allow you to switch the list of ads that have been
          reviewed and marked as interesting and have a brand and color. When
          you are looking at reviewed ads, you can filter by brand.
          <br />
          The first task as a reviewer is to use these two buttons to eliminate
          ads:
          <ul>
            <li>
              "Delete" <Icon style={{ color: "blue" }}>delete_icon</Icon> if it
              is not something we are interested in tracking, such as toddler
              trikes, bike parts, or clothing.
            </li>
            <li>
              "Sold" <Icon style={{ color: "blue" }}>shopping_cart_icon</Icon>{" "}
              if it has already been sold by the time you are looking at the ad.
            </li>
          </ul>
          <p>
            Any ad that does not fall these categories is something that might
            be a match to a reported theft. Enter the brand, color, and
            (optionally) model; then click the green check mark{" "}
            <Icon style={{ color: "green" }}>check_icon</Icon>. The card will
            turn a light shade of gray, indicating that you have completed the
            review.
          </p>
          <p>
            The matching process requires a perfect match on brand and color. If
            the model is entered, it requires a match on the first word of model
            and any associated number. It is best to select the brand from the
            dropdown. If you encounter a new brand, enter it manually. Color can
            be challenging. If a bike has several colors, use the color at the
            head-badge on the front of the steering tube. The Bianchi green/blue
            is always difficult. What is most important is that we are
            consistent between the colors of ads and the colors of theft
            reports.
          </p>
          <p>
            You can also use the "Flag"{" "}
            <Icon style={{ color: "red" }}>flag_icon</Icon> if you suspect that
            the bike is stolen and want to retain the ad even after it is sold.
          </p>
          If the system has captured information about the online profile of the
          seller, the silhouette at the bottom of the ad will be black and can
          be clicked. Clicking on it will open a new tab with details about the
          seller.
          <h3>Thefts</h3>
          Clicking on the <a href="./thefts">THEFTS</a> tab displays the list of
          thefts that have been captured by the system, in reverse chronological
          order. Although brand, color and model is imported from theBikeIndex
          report, it is important verify/correct the information because that
          drives the matching process.
          <h3>Sellers</h3>
          <p>
            From an ad that has seller information, use the silhouette/image to
            navigate tothe seller's details. The page provides a place for you
            to enter notes about the seller's birthdate, trust-worthiness or
            criminal record. The page displays thumbnails for all the ads that
            seller has listed.
            <br />
            You can also click on the <a href="./sellers">SELLERS</a> tab to
            display the list of all sellers that have captured by the system.
          </p>
          <h3>Matches</h3>
          <p>
            This tab lists matches that have been identified by the system. For
            each unreviewed match, examine it carefully and determine whether to
            mark it as a mismatch with the green thumbs up{" "}
            <Icon style={{ color: "green" }}>thumb_up_icon</Icon> or a match
            with the red flag <Icon style={{ color: "red" }}>flag_icon</Icon> .
          </p>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
