import React, { useState, useEffect } from "react";
import { MatchStatus } from "../models";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Grid,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { API, graphqlOperation } from "@aws-amplify/api";

import { onCreateMatch, onDeleteMatch } from "../graphql/subscriptions";
import MatchRepository from "../repositories/MatchRepository";
import { MatchView } from "../components/MatchView";
import { matchFilterAtom } from "../recoil/match";
import { useRecoilState } from "recoil";
import InfiniteScroll from "react-infinite-scroll-component";
import { ExpandMore } from "@mui/icons-material";

export default function Matches() {
  const [matches, setMatches] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);

  const [matchFilter, setMatchFilter] = useRecoilState(matchFilterAtom);
  const [expandFilter, setExpandFilter] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await fetchMatchs();
    };
    getData();

    const createSubscription = API.graphql(
      graphqlOperation(onCreateMatch)
    ).subscribe({
      next: ({ provider, value }) => {
        const newMatch = value.data.onCreateMatch;
        setMatches((list) => {
          return [newMatch].concat(list || []);
        });
      },
      error: (error) => console.warn(error),
    });
    const deleteSubscription = API.graphql(
      graphqlOperation(onDeleteMatch)
    ).subscribe({
      next: ({ provider, value }) => {
        const item = value.data.onDeleteMatch;
        setMatches((list) => {
          const array = list || [];
          var index = array.indexOf(item);
          if (index !== -1) {
            return array.splice(index, 1);
          } else {
            return array;
          }
        });
      },
      error: (error) => console.warn(error),
    });
    return () => {
      if (createSubscription) createSubscription.unsubscribe();
      if (deleteSubscription) deleteSubscription.unsubscribe();
    };
  }, [matchFilter]);

  async function fetchMatchs() {
    if (matchFilter.advertisementId) {
      await listByStatusAdvertisement(
        matchFilter.status,
        matchFilter.advertisementId
      );
    } else if (matchFilter.theftId) {
      await listByStatusTheft(matchFilter.status, matchFilter.theftId);
    } else {
      await listByStatusAdvertisement(matchFilter.status, null);
    }
  }
  function handleStatusFilterChanged(e) {
    setCurrentToken(null);
    setMatches([]);
    setMatchFilter({ ...matchFilter, status: e.target.value });
  }
  async function listByStatusAdvertisement(status, advertisementId) {
    const { items, nextToken } =
      await MatchRepository.listByStatusAdvertisement(
        status,
        advertisementId,
        currentToken,
        6
      );
    setCurrentToken(nextToken);
    const newMatches = (matches || []).concat(items);
    setMatches(newMatches);
  }

  async function listByStatusTheft(status, theftId) {
    const { items, nextToken } =
      await MatchRepository.listByStatusAdvertisement(
        status,
        theftId,
        currentToken,
        6
      );
    setCurrentToken(nextToken);
    setMatches((matches || []).concat(items));
  }

  function buildMatches() {
    if (matches) {
      if (matches.length > 0) {
        return (
          <InfiniteScroll
            dataLength={matches ? matches.length : 0}
            next={fetchMatchs}
            hasMore={currentToken != null}
          >
            <Grid container>
              {matches.map((match) => {
                return (
                  <Grid
                    item
                    key={match.id}
                    sx={{
                      width: "612px",
                      border: "solid",
                      borderWidth: "2px",
                      margin: "4px",
                      padding: "2px",
                    }}
                  >
                    <MatchView item={match} />
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        );
      } else {
        return <Typography>No Matches</Typography>;
      }
    } else {
      return <Typography>Loading</Typography>;
    }
  }
  return (
    <>
      <Accordion
        expanded={expandFilter}
        onChange={() => setExpandFilter(!expandFilter)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ flexShrink: 0 }}>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction={"row"}>
            <FormControlLabel
              control={
                <Select
                  onChange={handleStatusFilterChanged}
                  value={matchFilter.status}
                  sx={{
                    marginLeft: "4px",
                  }}
                >
                  <MenuItem value={MatchStatus.UNREVIEWED}>Unreviewed</MenuItem>
                  <MenuItem value={MatchStatus.MISMATCHED}>Mismatched</MenuItem>
                  <MenuItem value={MatchStatus.MATCHED}>Matched</MenuItem>
                </Select>
              }
              labelPlacement="start"
              label="Status"
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
      {buildMatches()}
    </>
  );
}
