const brands = [
  "All City",
  "Aventón",
  "Bacchetta",
  "Bianchi",
  "BionX",
  "Bottecchia",
  "Bridgestone",
  "Burley",
  "Campagnolo",
  "Cannondale",
  "Catrike",
  "Centurion",
  "Cervélo",
  "Cilo",
  "Cinelli",
  "Colnago",
  "Co-op",
  "Critical Cycles",
  "Dahon",
  "Dawes Cycles",
  "De Rosa",
  "DeBernardi",
  "Diamondback",
  "Eddy Merckx",
  "Electra Bicycle Company",
  "Ellsworth",
  "Evil",
  "Felt",
  "Firenze",
  "Focus",
  "Framed",
  "Fuji",
  "Fyxation",
  "Gary Klein",
  "Gary Fisher",
  "Gazelle",
  "Giant",
  "Gitane",
  "Globe",
  "GT Bicycles",
  "Haro",
  "Huffy",
  "Ibis",
  "Intense",
  "Jamis",
  "KHS Bicycles",
  "Klein Bikes",
  "Kona",
  "Kreitler",
  "Kuat",
  "Kuota",
  "Kuwahara",
  "Liv",
  "LeMond Racing Cycles",
  "Litespeed",
  "Lotus",
  "Lynskey",
  "Marin",
  "Maruishi",
  "Mercier",
  "Miyata",
  "Motobecane",
  "Murray",
  "Next",
  "Norco",
  "Northrock",
  "Orbea",
  "Panasonic",
  "Peugeot",
  "Pinarello",
  "Pivot",
  "Pure Fix",
  "Rad Power Bikes",
  "Raleigh",
  "Rans Designs",
  "Redline",
  "Retrospec",
  "Rivendell Bicycle Works",
  "Rocky Mountain Bicycles",
  "Salsa",
  "Santa Cruz",
  "Scattante",
  "Scott",
  "Schwinn",
  "SE Bikes",
  "Serotta",
  "Solé Bicycle Co.",
  "Specialized",
  "Stolen Bicycle Co.",
  "Surly",
  "Takara",
  "Tern",
  "Terry",
  "Tommaso",
  "Trek",
  "Univega",
  "Velo Orange",
  "Vilano",
  "Viscount",
  "Vitus",
  "Voodoo",
  "Woom",
  "Xtracycle",
  "Yeti",
  "Yuba",
  "Zoom",
]
// Mapping from the lowercase version of the first word to the full brand.
const brandMap =
  Object.fromEntries(brands.filter(s => !s.includes(" ")).map((b) =>
    [b.toLowerCase(), b]
  ));

function guessBrand(text) {
  const words = (text).split(/\s/);
  var matchingBrands = [];
  words.forEach((word) => {
    const b = brandMap[word.toLowerCase()];
    if (b) {
      matchingBrands.push(b);
    }
  });
  if (matchingBrands.length === 1) {
    return matchingBrands[0];
  }
}
const modelsByBrand = {
  "Cannondale": [
    "Althea",
    "Bad Boy",
    "Catalyst",
    "CAAD",
    "Cujo",
    "Trail",
    "City",
    "Habit",
    "Hooligan",
    "Jekyll",
    "Optimo",
    "Quick",
    "Rush",
    "Scalpel",
    "Super Six",
    "Synapse",
    "Topstone",
    "Trigger",
  ],
  "Giant": [
    "Boulder",
    "Bowery",
    "Brava",
    "City",
    "Contend",
    "Cypress",
    "Defy",
    "Escape",
    "Fastroad",
    "Fathom",
    "Roam",
    "Trance",
    "Iguana",
    "FCR",
    "OCR",
    "Rapid",
    "Reign",
    "Revel",
    "Rincon",
    "Rove",
    "Sedona",
    "Stance",
    "Talon",
    "TCR",
  ],
  "Rad Power Bikes": [
    "Rad City",
    "Rad Mini",
    "Rad Mission",
    "Rad Power",
    "Rad Rover",
    "Rad Runner",
    "Rad Wagon",
  ],
  "Raleigh": [
    "Alyssa",
    "Cadent",
    "Capri",
    "Clubman",
    "Detour",
    "Misceo",
    "Merit",
    "Militis",
    "Mojave",
    "Passage",
    "Revenio",
    "RX",
    "Redux",
    "Route",
    "Talus",
    "Technium",
    "Tokul",
    "Venture",
    "Willard",
  ],
  "Specialized": [
    "Allez",
    "Amira",
    "Ariel",
    "Chisel",
    "Creo",
    "Crossroads",
    "Crosstrail",
    "Camber",
    "Carve",
    "Chisel",
    "Como",
    "Crave",
    "Creo",
    "Crux",
    "Diverge",
    "Dolce",
    "Daily",
    "Enduro",
    "Epic",
    "FSR",
    "Fatboy",
    "Fuse",
    "Hardrock",
    "Jett",
    "Jynx",
    "Langster",
    "Levo",
    "Myka",
    "Pitch",
    "Rhyme",
    "Riprock",
    "Rockhopper",
    "Roll",
    "Roubaix",
    "Ruby",
    "Sirrus",
    "SWorks",
    "Stumpjumper",
    "Secteur",
    "Sequioa",
    "Tarmac",
    "Tricross",
    "Tarmac",
    "Vado",
    "Venge",
    "Vienna",
    "Vita",
  ],
  "Surly": [
    "Big Dummy",
    "Big Easy",
    "Bridge Club",
    "Crosscheck",
    "Disc Trucker",
    "Instigator",
    "Karate Monkey",
    "Krampus",
    "Long Haul Trucker",
    "Lowside",
    "Moonlander",
    "Midnight Special",
    "Ogre",
    "Straggler",
    "Pugsley",
    "Rat Pack",
    "Timberjack",
    "Troll",
    "Wednesday",
  ],
  "Trek": [

    "Allant",
    "Alpha",
    "Antelope",
    "Cali",
    "Checkpoint",
    "Crockett",
    "Cronus",
    "Crossrip",
    "District",
    "Domane",
    "Dual Sport",
    "Emonda",
    "Fuel",
    "FX",
    "Lexa",
    "Lync",
    "Madone",
    "Marlin",
    "Multitrack",
    "Neko",
    "Pilot",
    "Powerfly",
    "Procaliber",
    "Remedy",
    "Rail",
    "Roscoe",
    "Skye",
    "Session",
    "Silque",
    "Singletrack",
    "Slash",
    "Stache",
    "Superfly",
    "Verve",
    "Top Fuel",
    "Versa",
    "WSD",
    "X-Caliber",
  ],
}

module.exports = { brands, brandMap, guessBrand, modelsByBrand }
