import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  AppBar,
  Button,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const MobileNavigation = () => {
  const { signOut } = useAuthenticator();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (b) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(b);
  };

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <Icon style={{ color: "black" }}>menu_icon</Icon>
      </IconButton>

      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        <List>
          <ListItem onClick={toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/ads">
              Ads
            </Button>
          </ListItem>
          <ListItem onClick={toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/thefts">
              Thefts
            </Button>
          </ListItem>
          <ListItem onClick={toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/matches">
              Matches
            </Button>
          </ListItem>
          <ListItem onClick={toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/sellers">
              Sellers
            </Button>
          </ListItem>
          <ListItem onClick={toggleDrawer(false)}>
            <Button color="inherit" component={Link} to="/reports">
              Reports
            </Button>
          </ListItem>
          <ListItem onClick={signOut}>
            <Button color="inherit">Sign out</Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
const Navigation = () => {
  const { signOut } = useAuthenticator();

  return (
    <>
      <Box sx={{ flexGrow: 1, display: "flex" }}>
        <Button color="inherit" component={Link} to="/ads">
          Ads
        </Button>
        <Button color="inherit" component={Link} to="/thefts">
          Thefts
        </Button>
        <Button color="inherit" component={Link} to="/matches">
          Matches
        </Button>
        <Button color="inherit" component={Link} to="/sellers">
          Sellers
        </Button>
        <Button color="inherit" component={Link} to="/reports">
          Reports
        </Button>
      </Box>
      <Button color="inherit" onClick={signOut}>
        Sign out
      </Button>
    </>
  );
};

const NavBar = () => {
  const desktop = useMediaQuery("(min-width:600px)");
  const nav = desktop ? <Navigation /> : <MobileNavigation />;
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" component={Link} to="/home">
            <img
              src="/icon.png"
              alt="icon"
              style={{
                borderRadius: "50%",
                backgroundColor: "white",
                height: "30px",
              }}
            />
          </Button>
          {nav}
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default NavBar;
